.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
   /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.duckAnimation {
  animation: walk 5s linear infinite;
  
}

.duck {
  margin: 0 auto;
  white-space: nowrap;
  overflow-x: hidden;
}

.duck img {
  display: inline-block;
  padding-left: 15%;
  animation: walk 5s -2.5s linear infinite;
}

.duck2 img {
  animation-delay: 0s;
}

@keyframes walk {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
